.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  position: relative;
}

.copyrignt-mobile{
  display: none;
  align-items: center;
  justify-content: center;

}


/* Landscape phones and down */
@media (max-width: 480px) {
  .App {
    display: flex;
    overflow: visible !important;
    overflow-x: visible;
    flex-direction: column;
    height: fit-content;
    width: 100vw;
  }
  .no-scroll{
    overflow: hidden !important;
  }
  .copyrignt-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  
  }
}

/* Landscape phone to portrait tablet */
@media (min-width: 480px) and (max-width: 767px) {  
  .App {
    display: flex;
    overflow: visible !important;
    overflow-x: visible;
    flex-direction: column;
    height: fit-content;
  }
  .no-scroll{
    overflow: hidden !important;
  }
  .copyrignt-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    align-self: flex-end;
  
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 1024px) {  
  .App {
    display: flex;
    overflow: visible !important;
    overflow-x: visible;
    flex-direction: column;
    height: fit-content;
  }
  .no-scroll{
    overflow: hidden !important;
  }
  .copyrignt-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  
  }
}

/* Large desktop */
@media (min-width: 1200px) {  }

