.About{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-self: center;
    height: fit-content;
    color: var(--portfolio-text-grey-darker);
}

.about-textwrap{
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 120px;
    padding-bottom: 60px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    font-size: 1.45em;
    line-height: 1.2em;
}

.text-highlight{
    color: var(--portfolio-text-black);
}

.about-name{
    font-size: 1.3em;
    position: relative;
    font-style: italic;
    right: 30px;
}
.about-imgwrap{
    padding: 20px;
    grid-area: 1 / 2 / 3 / 3;
}
.about-imgwrap img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-kontakt{
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    
}

.mail-copy{
    font-size: 1em;
}

.kontakt-odkaz{
    transition: color 0.2s ease-in-out;
}
.kontakt-odkaz:hover{
    color: var(--portfolio-text-black);
}
.kontakt-item{
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
}

a{
    transition: color 0.2s ease-in-out;
}

a:link {
    text-decoration: none;
    color: var(--portfolio-text-grey);
  }
  
  a:visited {
    text-decoration: none;
    color: var(--portfolio-text-grey);
  }
  
  a:hover {
    text-decoration: none;
    color: var(--portfolio-text-black);
  }
  
  a:active {
    text-decoration: none;
    color: var(--portfolio-text-grey);
  }

  /* Landscape phones and down */
@media (max-width: 480px) {
    .About{
        width: inherit;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-top: 30px;
    }
    .about-textwrap{
        font-size: 1.3em;
        max-width: inherit;
        padding: 20px;
        text-align: justify;
    }
    .about-name{
        font-size: 1.3em;
        position: relative;
        font-style: italic;
        right: 15px;
    }
    .about-kontakt{
        padding:20px;
        
    }

  }
  
  /* Landscape phone to portrait tablet */
  @media (min-width: 480px) and (max-width: 767px) {  
    .About{
        width: inherit;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-top: 30px;
    }
    .about-textwrap{
        font-size: 1.3em;
        max-width: inherit;
        padding: 20px;
        text-align: justify;
    }
    .about-name{
        font-size: 1.3em;
        position: relative;
        font-style: italic;
        right: 15px;
    }
    .about-kontakt{
        padding:20px;
        
    }
  }
  
  /* Portrait tablet to landscape and desktop */
  @media (min-width: 768px) and (max-width: 1024px) {  
    .About{
        width: inherit;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-top: 60px;
    }
    .about-textwrap{
        font-size: 1.3em;
        max-width: inherit;
        padding: 20px;
        text-align: justify;
    }
    .about-name{
        font-size: 1.3em;
        position: relative;
        font-style: italic;
        right: 15px;
    }
    .about-kontakt{
        padding:20px;
        
    }
  }
  
  /* Large desktop */
  @media (min-width: 1024px) and (max-width: 1680px) {  
    .About{
        width: inherit;
        position: relative;
    }
    .about-textwrap{
        font-size: 1.3em;
        max-width: inherit;
        padding: 20px;
        text-align: justify;
    }
    .about-name{
        font-size: 1.3em;
        position: relative;
        font-style: italic;
        right: 15px;
    }
    .about-kontakt{
        padding:20px;
        
    }
  }