.LockGallery {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.lock-form{
    display: flex;
    padding-top: 20vh;
    flex-direction: column;

}

.lock-gallery-show{
    width: inherit;
}

.back-button{
    z-index: 99;
    position: sticky;
    top: 15px;
    left: 30px;
    color: var(--portfolio-text-grey);
    font-size: 1.6em;
    background-color: #fff;
    background-clip: padding-box;
    outline: 20px solid;
    outline-color: #fff;
    outline-offset: -2px;
    padding: 10px;
}

.back-button-text{
    width: fit-content;
    transition: all 0.3s ease-in-out;
    padding-left: 10px;
    padding-right: 10px;
}

.back-button-text:hover{
    color: var(--portfolio-text-black);
    cursor: pointer;
}


.heslo-header{
    font-size: 2em;
}

.lock-gallery-wrap{
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lock-gallery-heading{
    font-size: 1.8rem;
    color: var(--portfolio-text-black);
    padding-top: 40px;
    padding-bottom: 40px;
}

.lock-gallery-grid {
    width: inherit;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
}


.lock-card {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: rgb(61, 61, 61);
    border-radius: 10px;
    color: white;
    padding: 1rem;
    height: 8rem;
    min-width: 8rem;
    transition: all 0.3s ease-in-out;
    
}

.lock-card:hover{
    transform: scale(1.05);
    cursor: pointer;

}

.lock-name{
    font-size: 1.2em;
    text-shadow: 2px 2px 2px #000;
}  


  





    
/* Landscape phones and down */
@media (max-width: 480px) {
    .LockGallery{
        padding-top: 0px;
        padding-bottom: 30px;
        justify-content: flex-start;
    }
    .lock-gallery-textfield{
        padding-top: 120px;
    }
    .back-button{
        top: 25px;
    }
  }
  
  /* Landscape phone to portrait tablet */
  @media (min-width: 480px) and (max-width: 767px) { 
    .LockGallery{
        padding-top: 0px;
        padding-bottom: 30px;
        justify-content: flex-start;
    }
    .lock-gallery-textfield{
        padding-top: 120px;
    }
    .back-button{
        top: 25px;
    }
   }
  
  /* Portrait tablet to landscape and desktop */
  @media (min-width: 768px) and (max-width: 1024px) { 
    .LockGallery{
        padding-top: 0px;
        padding-bottom: 30px;
        justify-content: flex-start;
    }
    .lock-gallery-textfield{
        padding-top: 120px;
    }
    .back-button{
        top: 25px;
    }
   }
  
  /* Large desktop */
  @media (min-width: 1024px) and (max-width: 1680px) { 
    .LockGallery{
        padding-top: 0px;
        padding-bottom: 30px;
        justify-content: flex-start;
    }
    .lock-gallery-textfield{
        padding-top: 120px;
    }
    .back-button{
        top: 25px;
    }
   }