.Gallery {
    width: calc(((100vw/5)*4) - 40px);
    min-height: 95vh;
    margin-right: 20px;
    margin-top: 20px;
    position: absolute;
    align-self: flex-end;
    display: flex;
    transition: filter 0.5s ease-out;
}


.gallery-showing {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    transition: filter 0.1s ease;
}
.loader-wrap {
    z-index: 1000;
    transition: all 0.1s ease-in-out;
    visibility: hidden;
    scale: 3;
    position: fixed;
    top: 50vh;
    right: calc((((100vw/5)*4) - 70px)/2);
}

.loader-active {
    visibility: hidden;
}

.loading-active {
    filter: blur(20px);
}

.gallery-select-kontrola{
    display: none;
}


.hourglass-whole {
  --uib-size: 45px;
  --uib-color: rgba(53,145,255,1);
  --uib-speed: 0.75s;
  --uib-bg-opacity: .2;
  position: relative;
  display: flex;
  flex-direction: column;
  height: var(--uib-size);
  width: var(--uib-size);
  transform: rotate(45deg);
  animation: rotate calc(var(--uib-speed) * 2) ease-in-out infinite;
}

.hourglass-half {
  --uib-half-size: calc(var(--uib-size) * 0.435);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-half-size);
  height: var(--uib-half-size);
  overflow: hidden;
  isolation: isolate;

  &:first-child {
    top: 8.25%;
    left: 8.25%;
    border-radius: 50% 50% calc(var(--uib-size) / 15);
  }

  &:last-child {
    bottom: 8.25%;
    right: 8.25%;
    transform: rotate(180deg);
    align-self: flex-end;
    border-radius: 50% 50% calc(var(--uib-size) / 15);

    &::after {
      animation-delay: calc(var(--uib-speed) * -1);
    }
  }

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--uib-color);
    opacity: var(--uib-bg-opacity);
    transition: background-color 0.3s ease;
  }

  &::after {
    content: '';
    position: relative;
    z-index: 1;
    display: block;
    background-color: var(--uib-color);
    height: 100%;
    transform: rotate(45deg) translate(-3%, 50%) scaleX(1.2);
    width: 100%;
    transform-origin: bottom right;
    border-radius: 0 0 calc(var(--uib-size) / 20) 0;
    animation: flow calc(var(--uib-speed) * 2) linear infinite both;
    transition: background-color 0.3s ease;
  }
}

@keyframes flow {
  0% {
    transform: rotate(45deg) translate(-3%, 50%) scaleX(1.2);
  }
  30% {
    transform: rotate(45deg) translate(115%, 50%) scaleX(1.2);
  }

  30.001%,
  50% {
    transform: rotate(0deg) translate(-85%, -85%) scaleX(1);
  }

  80%,
  100% {
    transform: rotate(0deg) translate(0%, 0%) scaleX(1);
  }
}

@keyframes rotate {
  0%,
  30% {
    transform: rotate(45deg);
  }

  50%,
  80% {
    transform: rotate(225deg);
  }

  100% {
    transform: rotate(405deg);
  }
}


/* Landscape phones and down */
@media (max-width: 480px) {
  .Gallery {
    position: relative;
    display: flex;
    justify-content: center;
    width: 95vw;
    min-height: fit-content;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    align-self: center;
    
    transition: filter 0.5s ease-out;
  }
  .loader-wrap {
    display: flex;
    z-index: 1000;
    scale: 3;
    max-width: 100vw;
    max-height: fit-content;
    position: fixed;

    top: 80vh;
    right: auto;
    margin: 0 auto;
}

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {  
  .Gallery {
    position: relative;
    display: flex;
    justify-content: center;
    width: 95vw;
    min-height: fit-content;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    align-self: center;
    
    transition: filter 0.5s ease-out;
  }
  .loader-wrap {
    display: flex;
    z-index: 1000;
    scale: 3;
    max-width: 100vw;
    max-height: fit-content;
    position: fixed;

    top: 80vh;
    right: auto;
    margin: 0 auto;
}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 1024px) {  
  .Gallery {
    position: relative;
    display: flex;
    justify-content: center;
    width: 95vw;
    min-height: fit-content;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    align-self: center;
    
    transition: filter 0.5s ease-out;
  }
  .loader-wrap {
    display: flex;
    z-index: 1000;
    scale: 3;
    max-width: 100vw;
    max-height: fit-content;
    position: fixed;

    top: 80vh;
    right: auto;
    margin: 0 auto;
}
}

/* Large desktop */
@media (min-width: 1200px) {  }
